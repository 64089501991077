<template>
    <div>
        <div ref="editor"></div>
        <p class="text-caption my-4 mx-4"><v-icon small color="info">mdi-information</v-icon> Keep in mind: While copying and pasting text from other applications is supported, it can
            sometimes lead to unexpected results. It may disrupt the page layout, especially on mobile devices, or cause
            other unforeseen issues. So, proceed with caution when using this functionality.</p>
    </div>
</template>

<script>
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
    name: "RichEditor",
    props: {
        value: {
            type: String,
            default: "",
        }
    },

    data() {
        return {
            text: this.value,
            editor: null,
        };
    },

    mounted() {
        let _this = this;

        this.$nextTick(() => {
            this.editor = new Quill(this.$refs.editor, {
                modules: {
                    toolbar: [
                        [
                            {
                                header: [1, 2, 3, 4, false],
                            },
                        ],
                        ["bold", "italic", "underline", "strike", "blockquote", "link", "image", { 'color': [] }, { 'background': [] }, { 'align': [] }],
                    ],
                },
                theme: "snow",
                formats: ["bold", "underline", "header", "italic", 'link', "strike", "blockquote", "link", "image", "color", "background", "align"],
                placeholder: "Write you decription here...",
            });

            this.editor.on("text-change", function () {
                return _this.update();
            });

            this._setValue(this.text);
        })

    },

    methods: {
        update() {
            this.text = this.editor.getText() ? this.editor.root.innerHTML : "";
            this.$emit("text-change", this.text);
        },

        _setValue(value) {
            if (value) {
                if (!value.startsWith('<')) {
                    value = `<p>${value}</p>`;
                }

                this.text = value;
                this.editor.root.innerHTML = this.value;
            }
        }
    },

    watch: {
        value: {
            handler(value) {
                if (value != this.text) {
                    this._setValue(value);
                }
            },
            immediate: true
        }
    }
};
</script>